.filterButton{
    background-color: #F4F7FA ;
    height: 10px;
    border:1px solid rgba(0,126,191,1);
    width:10px;
    border-radius: 50%;
display: flex;
   align-items: center;
    padding: 18px,

}
.SOWtitle{
font-size: 22px;
}
.sowHeading{
    font-size: 20px;
}
// .MuiAccordion-root ::before{
// background-color: white !important;
// }