.Sidebar {
  &__list {
    display: flex;
    color: black !important;
    font-weight: 600 !important;
    flex-direction: column;
    gap: 5px;
  }

  &__subList {

    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}