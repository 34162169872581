*{
    margin: 0px;
    padding:0px;
    font-family: 'Open Sans', sans-serif !important; 
    font-size: 17px;
    // font-weight: 600 !important;       
    // font-family: 'Poppins', sans-serif;
}

body
{
    background-color: #F4F7FA !important;  
}
