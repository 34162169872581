.Users
{
    .display__style__1
    {
        display: flex;
        justify-content: end;
    }

    .icon_images
{
    height:22px;
    width:22px;
    margin-right: 8px;
}

}